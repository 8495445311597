body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height : 100%;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;

}